import React from 'react';
import Header from './Header';
import Footer from './Footer';
import '../styles/styles.scss';

export default class App extends React.PureComponent {
  render() {
    return (
      <div>
        <Header siteTitle={this.props.title} />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}