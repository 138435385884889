import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import App from './App';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        website: site {
          siteMetadata {
            title
            lang
          }
        }
      }
    `}
    render={(data) => {
      return (
        <>
          <Helmet
            title={data.website.siteMetadata.title}
            meta={[
              { name: 'description', content: 'Sample' },
              { name: 'keywords', content: 'sample, something' },
            ]}
          >
            <html lang={`${data.website.siteMetadata.lang}`} />
          </Helmet>
          <App {...{ children: children, title: data.website.siteMetadata.title}} />
        </>
      );
    }}
  />
);

export default Layout;
