import React from 'react';
// import { Link } from 'gatsby';

export default (props) => (
  <header>
    <div>
      {/* <Link to="/">Index</Link>
      <Link to="/works">Works</Link>
      <Link to="/contact">Contact</Link> */}
    </div>
  </header>
);